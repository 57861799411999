import axios from 'axios'
import authHeader from './auth-header'
// import helpers from "@/helpers/helper";


const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class InvoiceService {

    getInvoiceCount(data) {
        var page = data ? data.page : 1;
        var search = data ? data.search : null;
        return axios.get(VUE_APP_BASE_URL + "invoice/count", { headers: authHeader() }).then((response) => {
            return response.data;
        });
    } 
    getopenInvoice(data) {
        var page = data ? data.page : 1;
        return axios.get(VUE_APP_BASE_URL + "invoice/invoices" + "?page=" + page + "&size=" + data.size, { headers: authHeader() }).then((response) => {
            return response.data;
        });
    }
    getVoidedInvoice(data) {
        return axios.get(VUE_APP_BASE_URL + "invoice/voided-invoice" + "?size=" + data.size, { headers: authHeader() }).then((response) => {
            return response.data;
        });
    }
    getCloseInvoice(data) {
        return axios.get(VUE_APP_BASE_URL + "invoice/closed-invoice" + "?size=" + data.size, { headers: authHeader() }).then((response) => {
            return response.data;
        });
    }
    getAllInvoice(data) {
        return axios.get(VUE_APP_BASE_URL + "invoice/all-invoice" + "?size=" + data.size, { headers: authHeader() }).then((response) => {
            return response.data;
        });
    }
    creatInvoice(formData) {
        return axios.post(VUE_APP_BASE_URL + 'invoice/invoices', formData, { headers: authHeader() });
    }
    payReciptMpesa(data) {
        return axios.post(VUE_APP_BASE_URL + 'invoice/pay-invoice', data, { headers: authHeader() });
    }
    payInvoice(data) {
        return axios.post(VUE_APP_BASE_URL + 'invoice/pay-invoice', data, { headers: authHeader() });
    }
    closeInvoice(data) {
        return axios.post(VUE_APP_BASE_URL + 'invoice/close-invoice', data, { headers: authHeader() });
    }
}

export default new InvoiceService();  